



































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import StoreModal from '@/pages/P0921/StoreModal.vue';
import FaqBox from '@/components/FaqBox.vue';

@Component({
  components: {
    StoreModal,
    FaqBox
  }
})
export default class P0921 extends Vue {
  isShowStoreModal = Array(11).fill(false); // 店舗数に応じてサイズを調整

  // モーダルを開く
  openModal(index: number) {
    this.$set(this.isShowStoreModal, index, true);
  }

  mounted() {
    // <body>要素にクラスを追加
    document.body.classList.add('isCafetime');
  }
  beforeDestroy() {
    // <body>要素からクラスを削除
    document.body.classList.remove('isCafetime');
  }

  // OPアプリ経由のアクセスであるか判定
  get isOpApp() {
    return this.$auth.isOpApp();
  }
}
